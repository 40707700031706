import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

// Components
import SecondaryLanding from "../../layouts/secondary-landing";
import SEO from "../../components/seo/seo";
import Breadcrumb from "../../components/breadcrumb/breadcrumb";
import Button from "../../components/custom-widgets/button";
import BestBanksDefault from "../../components/best-banks/best-banks-default";

const WorkplaceBanking = () => {
  const imgData = useStaticQuery(graphql`
    query {
      wafdWorkPerksLogo: file(relativePath: { eq: "workplace-banking/wafd-work-perks-275x89.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      wafdWorkPerksColImage: file(relativePath: { eq: "workplace-banking/thumbnail-work-perks-082624.jpg" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `);
  const title = "Workperks";
  const description = "Your company has signed up for WaFd Work Perks... and you get the benefits!";

  const SEOData = {
    title: title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        property: "og:title",
        content: title
      },
      {
        name: "description",
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/workplace-banking"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/workplace-banking/og-work-perks-082624.jpg"
      },
      {
        name: "robots",
        content: "noindex, nofollow"
      }
    ]
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/workplace-banking",
      title: "Workplace Banking"
    },
    {
      id: 2,
      title: "Workperks",
      active: true
    }
  ];

  return (
    <SecondaryLanding footerBorder={false}>
      <SEO {...SEOData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container" id="intro-section">
        <div className="row">
          <div className="col-lg-8 order-2 order-lg-1">
            <h2 className="text-green-60">Your company has signed up for WaFd Work Perks… and you get the benefits!</h2>
            <h4>It's time to expect more from your Bank.</h4>
            <h4 className="mb-0">
              Choose an account with protection and coverage for you and your family with our Rewards, Premium Rewards,
              or Interest Checking accounts, or a value-added *Free Checking with no minimum balance or monthly fees.
              Either way, you get access to extras with Work Perks.
            </h4>
          </div>
          <div className="col-lg-4 order-1 order-lg-2">
            <GatsbyImage
              image={imgData.wafdWorkPerksLogo.childImageSharp.gatsbyImageData}
              alt="WaFd Work Perks Logo"
              quality="100"
              placeholder="blurred"
            />
          </div>
        </div>
      </section>
      <section className="bg-light">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 mb-4 mb-lg-0">
              <GatsbyImage
                image={imgData.wafdWorkPerksColImage.childImageSharp.gatsbyImageData}
                alt="Group of laughing coworkers."
                quality="100"
                className="mb-2"
                placeholder="blurred"
              />
              <h4>
                Enjoy a full-service account with 24-hour banking. Call us anytime at{" "}
                <a href="tel:800-324-9375" id="blog-c3-tel-link">
                  800-324-9375
                </a>
                .
              </h4>
              <h4> Let us know that your employer is participating in Work Perks to receive additional benefits.</h4>
              <div className="row">
                <div className="col-sm-6 col-lg-12 col-xl-6 mb-3">
                  <Link id="find-local-branch-link" to="/locations" className="btn btn-link btn-block">
                    Find a Local Branch
                  </Link>
                </div>
                <div className="col-sm-6 col-lg-12 col-xl-6">
                  <a
                    id="download-coupon-sheet-link"
                    class="btn btn-link btn-block"
                    href="/documents/coupon-sheet-for-employees.pdf"
                    target="_blank"
                  >
                    Download Coupon Sheet
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <h3 className="text-green-60 font-weight-bold">
                All Work Perks Accounts include the below items, with a handy coupon sheet available to bring into a
                branch near you.
              </h3>
              <ul id="work-perks-list">
                <li id="work-perks-list-item-1">Online and mobile banking with bill pay and mobile deposit</li>
                <li id="work-perks-list-item-2">Complimentary first box of checks</li>
                <li id="work-perks-list-item-3">Free 3x5 Safe Deposit Box where available</li>
                <li id="work-perks-list-item-4">Get an extra 0.10% over our published CD rates</li>
                <li id="work-perks-list-item-5">
                  Complimentary access to{" "}
                  <a href="/personal-banking/greenlight-kids-debit-card" id="work-perks-greenlight-link">
                    Greenlight
                  </a>{" "}
                  to help kids learn how to manage their own money
                </li>
                <li id="work-perks-list-item-6">
                  Up to $5,000 off mortgage, refinance or custom construction loan origination fees**
                </li>
                <li id="work-perks-list-item-7">
                  1.5% discount for 6 months on published Home Equity Line of Credit rates***
                </li>
                <li id="work-perks-list-item-9">
                  Access to{" "}
                  <a
                    href="https://wafdbank.banzai.org/wellness/collections"
                    id="work-perks-banzai-link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Banzai!
                  </a>{" "}
                  an interactive finance platform for all ages with tools, calculators, and more
                </li>
                <li id="work-perks-list-item-10">
                  Debit card with fee-free access to thousands of MoneyPass<sup>&trade;</sup> ATMs
                </li>
                <li id="work-perks-list-item-11">
                  Person-to-person payments with{" "}
                  <Link id="work-perks-zelle-link" to="/personal-banking/mobile-banking-app/zelle">
                    Zelle
                  </Link>
                  <sup>&reg;</sup>
                </li>
              </ul>
              <h3 className="text-green-60 font-weight-bold">
                Choose Rewards, Premium Rewards, or Interest Checking to receive these additional benefits:
              </h3>
              <ul id="additional-benefits-list">
                <li id="additional-benefits-list-item-1">5¢ Per Gallon Gas Savings</li>
                <li id="additional-benefits-list-item-2">Shopping Discounts</li>
                <li id="additional-benefits-list-item-3">Travel and Leisure Discounts</li>
                <li id="additional-benefits-list-item-4">Mobile Phone Insurance</li>
                <li id="additional-benefits-list-item-5">Credit Monitoring and Identity Theft Resolution Service</li>
                <li id="additional-benefits-list-item-6">Buyer's Protection/Extended Warranty</li>
                <li id="additional-benefits-list-item-7">And More!</li>
              </ul>
              <Button
                id="compare-accounts-cta"
                containerClass="mb-3 text-center text-sm-left"
                type="link"
                class="btn-primary"
                text="Compare Accounts"
                url="/personal-banking/checking-account"
                showIcon={false}
              />
              <p className="text-muted mb-0">
                All checking accounts require a $25 initial deposit to open. Some account benefits and rewards require
                registration and activation.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="container text-sm text-muted">
        <p className="font-weight-bold mb-2">Fees:</p>
        <p className="mb-2">
          *Free Checking has no minimum balance requirement or monthly fees. Nonsufficient funds fees may apply.
        </p>
        <p className="mb-2">
          Rewards Checking is $6 per month and Premium Rewards Checking is $9 per month with no minimum balance
          requirements.
        </p>
        <p className="mb-2">
          Interest Checking is $12 per month; fee is waived with $5,000 average daily balance or with $50,000 in
          eligible accounts. Interest is earned on all balances.
        </p>
        <p className="mb-2">
          **Up to $5,000 off loan origination fees, including credit report, flood certification, and tax monitoring
          fees. This offer is available on owner-occupied, primary residence, purchase and refinance transactions, and
          custom construction loans. $5,000 off loan origination fee requires automatic mortgage payment via EZ-Pay from
          a WaFd checking account, payroll direct deposit, one or more online Bill Pay and monthly reserves. Financing
          for second homes, investment properties and lot loans are not eligible for discounts. All loans are subject to
          credit approval.
        </p>
        <p className="mb-2">
          ***Requires direct deposit and EZ Pay automatic payment option from your WaFd account. 6 months fixed
          introductory rate with a 1.5% discount; rate is variable thereafter.
        </p>
        <p className="mb-2">
          Certificate of Deposit (CD) requires just $1,000 to open. Includes IRAs. Maximum $100,000 account balance.
        </p>
        <p className="mb-0">
          WaFd Bank customers are eligible for the Greenlight SELECT plan at no cost when they connect their WaFd Bank
          account as the Greenlight funding source for the entirety of the promotion. Subject to minimum balance
          requirements and identity verification. Upgrades will result in additional fees. Upon termination of
          promotion, customers will be responsible for associated monthly fees. See terms for details. Offer ends
          02/03/2025. Offer subject to change and partner participation.
        </p>
      </section>
      <BestBanksDefault />
    </SecondaryLanding>
  );
};

export default WorkplaceBanking;
